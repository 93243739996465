import React from "react";
import { OutboundLink } from "gatsby-plugin-google-analytics";
import { FaGlobe } from "@react-icons/all-files/fa/FaGlobe";

import Head from "../../components/head.js";

import Layout from "../../components/layout";

const Boscher = () => {
  return (
    <Layout>
      <Head title="Boscher Theodor" />
      <h3>Boscher Theodor</h3>
      <h4>
        <FaGlobe style={{ marginRight: 7, paddingTop: 5 }} />
        Germany
      </h4>
      <p>
        <OutboundLink href="https://ello.co/boscher">Ello</OutboundLink>
      </p>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          marginBottom: 75,
        }}
      >
        <img
          src="https://assets0.ello.co/uploads/asset/attachment/15874948/ello-optimized-789eaa18.jpg"
          alt="Boscher Theodor art"
        />
        <p>SFX (green), 2012, 42 x 29,7 cm, mixed media on paper.</p>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          marginBottom: 75,
        }}
      >
        <img
          src="https://assets1.ello.co/uploads/asset/attachment/15874953/ello-optimized-537bfb9c.jpg"
          alt="Boscher Theodor art"
        />
        <p style={{ marginBottom: 0 }}>
          LICHTHOF, edition of 100, 4 AP | 40 x 30 cm | Archival Fine Art Print
          on cardboard
        </p>
        <p>
          published on the occasion of the 700th anniversary of the Cologne
          Cathedral | Domforum | Cologne | 2022
        </p>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          marginBottom: 75,
        }}
      >
        <img
          src="https://assets1.ello.co/uploads/asset/attachment/14490605/ello-optimized-782fae7e.jpg"
          alt="Boscher Theodor art"
        />
        <p style={{ marginBottom: 0 }}>👉 Studio view</p>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          marginBottom: 75,
        }}
      >
        <img
          src="https://assets1.ello.co/uploads/asset/attachment/11299065/ello-optimized-b0c3cc55.jpg"
          alt="Boscher Theodor art"
        />
      </div>
    </Layout>
  );
};

export default Boscher;
